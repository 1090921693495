// ../http-client/dist/index.js
var HttpError = class extends Error {
  constructor(response) {
    const code = response.status || response.status === 0 ? response.status : "";
    const title = response.statusText || "";
    const status = `${code} ${title}`.trim();
    const reason = status ? `status code ${status}` : "an unknown error";
    super(`Request failed with ${reason}`);
    this.name = "HttpError";
    this.response = response;
  }
};

// src/helpers.ts
var GODARE_CATEGORY_ID = 1208;
var TEKNO_CATEGORY_ID = 100543;
var Vendor = {
  Aftonbladet: "ab",
  Godare: "godare",
  Tekno: "tekno",
  VGTV: "vgtv"
};
var CHILD_GODARE_CATEGORY_IDS = [
  1062,
  1221,
  1268,
  1306,
  1307,
  1308,
  1309,
  1325,
  1327,
  1328,
  1329,
  1330,
  1342,
  1343,
  1372,
  1384,
  1425,
  1426,
  1427,
  1428,
  1430,
  1437,
  1439,
  1440,
  1441,
  1447,
  1449,
  1450,
  1451,
  1452,
  1453,
  1462,
  1463,
  1464,
  1473,
  1474,
  1476,
  1477,
  1492,
  1493,
  1504,
  1513,
  1515,
  1520,
  1522
];
var resolveVendor = (vendor) => {
  if (vendor === Vendor.Godare) {
    return Vendor.Aftonbladet;
  }
  if (vendor === Vendor.Tekno) {
    return Vendor.VGTV;
  }
  return vendor;
};
var resolveFilters = (vendor, filter) => {
  if (vendor === Vendor.Godare) {
    const filters = (filter != null ? filter : "").split("|");
    filters.push(`category.id::${GODARE_CATEGORY_ID}`);
    return filters.join("|");
  }
  if (vendor === Vendor.Aftonbladet) {
    const filters = (filter != null ? filter : "").split("|");
    filters.push(`category.id<>${[...CHILD_GODARE_CATEGORY_IDS, GODARE_CATEGORY_ID].join(",")}`);
    return filters.join("|");
  }
  if (vendor === Vendor.Tekno) {
    const filters = (filter != null ? filter : "").split("|");
    filters.push(`category.id::${TEKNO_CATEGORY_ID}`);
    return filters.join("|");
  }
  return filter;
};
var isPartOfGodare = (asset) => {
  return asset.category.id === GODARE_CATEGORY_ID || asset.category.parentId === GODARE_CATEGORY_ID;
};
var isPartOfTekno = (asset) => {
  return asset.category.id === TEKNO_CATEGORY_ID;
};
var isPartOfVendor = (vendor, asset) => {
  if (vendor === Vendor.Godare) {
    return isPartOfGodare(asset);
  }
  if (vendor === Vendor.Aftonbladet) {
    return !isPartOfGodare(asset);
  }
  if (vendor === Vendor.Tekno) {
    return isPartOfTekno(asset);
  }
  return true;
};

// src/index.ts
var SVP_ASSET_ADDITIONAL = [
  "settings",
  "chapters",
  "cuePoints",
  "externalId",
  "externalCategoryId",
  "nextAsset",
  "sourceFiles",
  "subtitles",
  "metadata",
  "access",
  "tags",
  "stories"
].join("|");
var ASSET_ID_PATTERN = /^\d+$/;
var isAssetId = (assetId) => {
  return ASSET_ID_PATTERN.test(assetId);
};
var getAsset = (client) => async ({ assetId, vendor }, options) => {
  const searchParams = new URLSearchParams({ appName: "svp-api-client" });
  searchParams.set("additional", SVP_ASSET_ADDITIONAL);
  const response = await client(`/${resolveVendor(vendor)}/assets/${assetId}`, { ...options, searchParams });
  const asset = await response.json();
  if (!isPartOfVendor(vendor, asset)) {
    throw new HttpError({ status: 404, statusText: `not part of "${vendor}" vendor`, json: () => Promise.reject() });
  }
  return asset;
};
var searchAssets = (client) => async ({
  limit,
  filter,
  vendor
}, options) => {
  const searchParams = new URLSearchParams({ appName: "svp-api-client" });
  if (limit) {
    searchParams.set("limit", String(limit));
  }
  const filters = resolveFilters(vendor, filter);
  if (filters) {
    searchParams.set("filter", filters);
  }
  searchParams.set("additional", SVP_ASSET_ADDITIONAL);
  const response = await client(`/${resolveVendor(vendor)}/search`, { ...options, searchParams });
  const {
    _embedded: { assets }
  } = await response.json();
  return assets;
};
var assetApi = (client) => {
  return {
    getAsset: getAsset(client),
    searchAssets: searchAssets(client)
  };
};
var getCategories = (client) => async ({ vendor }, options) => {
  const response = await client(`/${resolveVendor(vendor)}/categories`, {
    ...options,
    searchParams: {
      appName: "svp-api-client",
      additional: SVP_ASSET_ADDITIONAL
    }
  });
  const {
    _embedded: { categories }
  } = await response.json();
  return categories;
};
var categoryApi = (client) => {
  return {
    getCategories: getCategories(client)
  };
};
var createSvpApi = (client) => {
  return {
    asset: assetApi(client),
    category: categoryApi(client)
  };
};
export {
  SVP_ASSET_ADDITIONAL,
  createSvpApi,
  isAssetId,
  isPartOfVendor,
  resolveFilters,
  resolveVendor
};
