import type { EventInput, Tracker } from '@spt-tracking/pulse-sdk';
import { subscribeTrackerToConsents, getConsentsForPulse } from '@vgtv/consent-manager';

import { createObjectBuilder, createProviderBuilder, createActorBuilder, createExperimentsBuilder } from './builders';

let trackerPromise: Promise<Tracker>;

const initTrackerInstance = () => {
  return new Promise<Tracker>((resolve) => {
    // ref https://github.schibsted.io/vg/frimand/blob/master/resources/js/tracking/pulse.js#L112
    window.pulse!('init', 'vg', { consents: getConsentsForPulse() }, null, 'vgtv-overlay-tracker');
    window.pulse!('vgtv-overlay-tracker', (tracker) => {
      resolve(tracker);
    });
  });
};

const initTracker = async () => {
  const tracker = await initTrackerInstance();

  subscribeTrackerToConsents(tracker);

  tracker.update({
    object: createObjectBuilder(),
    provider: createProviderBuilder(),
    experiments: createExperimentsBuilder(),
    actor: createActorBuilder(),
  });

  return tracker;
};

const getTracker = () => {
  if (trackerPromise) {
    return trackerPromise;
  }

  trackerPromise = initTracker();

  return trackerPromise;
};

export const trackEvent = async (data: EventInput) => {
  try {
    const tracker = await getTracker();

    return tracker.track('trackerEvent', data, {});
  } catch (err) {
    console.error(err);
  }
};
