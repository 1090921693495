import React from 'react';
import type { History } from 'history';
import type { Container } from 'react-dom';

import type { AppProps } from './App';
import { App } from './App';
import { HistoryProvider } from './components/History/History';
import { TranslationProvider } from './i18n';
import type { AssetStorage } from './components/AssetCache/AssetCache';
import { AssetCacheProvider } from './components/AssetCache/AssetCache';
import no from './i18n/locales/no';

const translations = {
  no,
};

interface Root {
  render(children: React.ReactNode): void;
  unmount(): void;
}

interface RootOptions {
  identifierPrefix?: string;
  onRecoverableError?: (error: unknown, errorInfo: ErrorInfo) => void;
}

interface ErrorInfo {
  digest?: string;
  componentStack?: string;
}

interface ReactDOMClient {
  createRoot(container: Container, options?: RootOptions): Root;
}

export const setupOverlay = ({
  containerId,
  history,
  assetCacheStorage,
  variant = 'mobile',
}: {
  containerId: string;
  history: History;
  assetCacheStorage?: AssetStorage;
  variant?: AppProps['variant'];
}) => {
  const container = document.getElementById(containerId)!;

  const Root = () => {
    return (
      <React.StrictMode>
        <HistoryProvider value={history}>
          <TranslationProvider locale="no" translations={translations}>
            <AssetCacheProvider storage={assetCacheStorage}>
              <App variant={variant} />
            </AssetCacheProvider>
          </TranslationProvider>
        </HistoryProvider>
      </React.StrictMode>
    );
  };

  // @ts-expect-error can't install react 18+
  import('react-dom/client')
    .catch(() => import('react-dom'))
    // eslint-disable-next-line @typescript-eslint/consistent-type-imports
    .then((ReactDOM: typeof import('react-dom') | ReactDOMClient) => {
      if ('createRoot' in ReactDOM) {
        ReactDOM.createRoot(container).render(<Root />);
      } else {
        ReactDOM.render(<Root />, container);
      }
    })
    .catch((err) => console.error('failed to render vivi app', err));
};
